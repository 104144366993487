import React, { Component } from 'react'
import HomeLayout from './HomeLayout'

class About extends Component {
  render() {
    return (
      <HomeLayout>
        <div>
            <h1>About Me</h1>
        </div>
      </HomeLayout>
    )
  }
}

export default About